import { combineReducers } from 'redux';

import loadDashboardDataError from './loadDashboardDataError';
import loadStatisticsError from './loadStatisticsError';


export default combineReducers({
  loadDashboardDataError,
  loadStatisticsError,
});
